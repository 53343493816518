import React, { useContext, useState } from 'react';
import {Stack, Icon} from '@shopify/polaris';
import {DeleteMajor} from '@shopify/polaris-icons';
import {FormContext} from '@thinkwell/react.common';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import {TextField} from '@thinkwell/react.polaris';
import EditQuestion from './EditQuestion'
import EditQuestionDrop from './EditQuestionDrop'

export default function EditRow(props) {
  const form = useContext(FormContext)

  const row = props.row
  const questions = row.questions || []

  const name = (attribute) => {
    return ['examples', props.exampleIndex, 'rows', props.index, attribute]
  }

  const onDeleteQuestion = (index) => {
    questions.splice(index, 1)
    form.onData(name('question'))(questions)
    form.onData(name('question_ids'))(questions.map((question) => question.id))
  }

  return (
    <div className="edit-row">
      <CKTextArea
        id={`example_${props.exampleIndex}_row_${props.index}_direction`}
        placeholder="Direction"
        name={name('direction')}
      />

      <div className="edit-questions">
        <EditQuestionDrop
          rowIndex={props.index}
          exampleIndex={props.exampleIndex}
          beforeQuestionId={questions.length && questions[0].id}
        />
        {
          questions.map((question, index) => {
            const nextQuestion = questions[index + 1];
            return [
              <EditQuestion
                key={question.id}
                question={question}
                rowIndex={props.index}
                exampleIndex={props.exampleIndex}
                onDelete={() => onDeleteQuestion(index)}
              />,
              <EditQuestionDrop
                key={`${question.id}_drop_after`}
                afterQuestionId={question.id}
                beforeQuestionId={nextQuestion && nextQuestion.id}
                rowIndex={props.index}
                exampleIndex={props.exampleIndex}
              />
            ]
          })
        }
      </div>
    </div>
  );
}
