import React, {useState, useRef} from 'react';
import {TopBar} from '@shopify/polaris';
import {SearchProvider} from '@thinkwell/react.common';
import {AppProvider} from '@thinkwell/react.polaris';
import { TemplateProvider } from '../contexts/Template'
import Template from './Template'
import EditTemplate from './EditTemplate'
import MainFrame from '../MainFrame';

export default function Show(props) {
  const navigateToRef = useRef()
  const [search, setSearch] = useState()
  const [addTemplateActive, setAddTemplateActive] = useState(false);
  const [isTemplateMenuOpen, setIsTemplateMenuOpen] = useState(false);
  const toggleIsTemplateMenuOpen = () => setIsTemplateMenuOpen((isTemplateMenuOpen) => !isTemplateMenuOpen);

  const templateMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Template', onAction: () => { setAddTemplateActive(true) }},
             {content: 'Logout', url: '/logout'}
           ]
         }
       ]}
       name="Templates"
       initials="T"
       open={isTemplateMenuOpen}
       onToggle={toggleIsTemplateMenuOpen}
     />
   );

  const onSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      navigateToRef.current(`templates?q=${encodeURIComponent(search)}`)
    }
  }

  const onSearchChange = (value) => {
    setSearch(value)
  }

  const onAddTemplate = (template) => {
    navigateToRef.current(`templates/${template.id}`)
  }

  return (
  <AppProvider>
    <SearchProvider>
      <MainFrame searchPlaceHolder={props.item.thinkwell_id} onSearchChange={onSearchChange}
      onSearchKeyPress={onSearchKeyPress} navigateToRef={navigateToRef} userMenu={templateMenuMarkup}>
        <TemplateProvider value={props.item}>
          { addTemplateActive ?
            <EditTemplate
              active={addTemplateActive}
              onActive={(active) => { if (active != addTemplateActive) { setAddTemplateActive(active) }}}
              onSave={onAddTemplate}
            /> :
            <Template navigateToRef={navigateToRef} />
          }
        </TemplateProvider>
      </MainFrame>
    </SearchProvider>
  </AppProvider>)
}
