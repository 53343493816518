import React, { useState, useEffect, useContext } from 'react';
import {Stack} from '@shopify/polaris';
import {FormProvider} from '@thinkwell/react.common';
import {EditModal, TextField} from '@thinkwell/react.polaris';
import {TagsContext} from '../contexts/Tags'

export default function EditTag(props) {
  const [tags, fetchTags, fetchTagsState, upsertTag] = useContext(TagsContext)

  const item = props.item || {}

  const onEditSave = (response) => {
    props.onSave && props.onSave(response)
    upsertTag(response)
  }

  return (
    <FormProvider data={{data: item}} rootName="tag"><EditModal
      active={props.active}
      onActive={props.onActive}
      onSave={onEditSave}
      url={`/tags${item.id ? `/${item.id}` : ''}.json`}
      method={item.id ? 'put' : 'post'}
      title={item.id ? "Edit Tag" : "Add Tag"}>
      <TextField required
      label="Value"
      name="value"
      />
    </EditModal></FormProvider>
  );
}
