import React, { useState } from 'react';

import {Items, useFetch} from '@thinkwell/react.common';
const TemplatesContext = React.createContext([{}, () => {}, {}, () => {}, () => {}, () => {}]);

const TemplatesProvider = (props) => {
  const [items, setItems, upsertItem, getItem, deleteItem] = Items(useState(props && props.value || []))
  const [fetchTemplatesState, fetchTemplates] = useFetch({onSuccess: setItems})

  return (
    <TemplatesContext.Provider value={[items, fetchTemplates, fetchTemplatesState, upsertItem, getItem, deleteItem]}>
      {props.children}
    </TemplatesContext.Provider>
  );
};

export { TemplatesContext, TemplatesProvider}
