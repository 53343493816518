import React, { useState } from 'react';

import {Items, useFetch} from '@thinkwell/react.common';
const TagsContext = React.createContext([{}, () => {}, {}, () => {}, () => {}, () => {}]);

const TagsProvider = (props) => {
  const [items, setItems, upsertItem, getItem, deleteItem] = Items(useState(props && props.value || []))
  const [fetchTagsState, fetchTags] = useFetch({onSuccess: setItems})

  return (
    <TagsContext.Provider value={[items, fetchTags, fetchTagsState, upsertItem, getItem, deleteItem]}>
      {props.children}
    </TagsContext.Provider>
  );
};

export { TagsContext, TagsProvider}
