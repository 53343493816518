import React, { useContext } from 'react';
import {FormContext, useEffect} from '@thinkwell/react.common';
import {Stack, Button} from '@shopify/polaris';
import EditAnswer from './EditAnswer'

export default function EditAnswers(props) {
  const form = useContext(FormContext)

  const answers = form.field(['serialized_data', 'answers'])

  const onCheckedAnswer = (id) => {
    // uncheck other answers
    answers.forEach((answer, index) => {
      if (answer.id != id) {
        answer.weight = 0
      } else {
        answer.weight = 100
      }
    })
    form.onData(['serialized_data', 'answers'])(answers)
  }
  const onDeleteAnswer = (index) => {
    answers.splice(index, 1)
    form.onData(['serialized_data', 'answers'])(answers)
  }
  const onAddAnswer = () => {
    const lastId = answers.map((answer) => answer.id).sort().pop() || 0
    answers.push({id: (lastId + 1)})
    form.onData(['serialized_data', 'answers'])(answers)
  }

  const questionType = form.field(['serialized_data', 'question_type'])
  useEffect(() => {
    if (!questionType) {
      form.onData(['serialized_data', 'question_type'])('multiple_choice_question')
    }
    if (!answers || answers.length == 0) {
      form.onData(['serialized_data', 'answers'])([{id: 1}])
    } else {
      form.onData(['serialized_data', 'answers'])(answers)
    }
  }, [])

  return (<Stack vertical>
    <h3 className="spacer">Answers</h3>
    { questionType == 'multiple_choice_question' ?
      <Stack>
        <div>Correct?</div>
        <Stack.Item fill>Html</Stack.Item>
        <Stack.Item fill>Comment</Stack.Item>
        <div></div>
      </Stack> : null
    }
    {
      (answers || []).map((answer, index) => {
        return <EditAnswer key={answer.id} answer={answer} index={index}
        onDelete={onDeleteAnswer} onChecked={onCheckedAnswer}/>
      })
    }
    <Button onClick={onAddAnswer}>Add Answer Choice</Button>
  </Stack>);
}
