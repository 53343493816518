import React, { useContext, useState } from 'react';
import {Stack, List} from '@shopify/polaris';
import { WorksheetContext } from '../contexts/Worksheet'
import ReactHtmlParser from 'react-html-parser'

export default function Row(props) {
  const [worksheet, setWorksheet, options] = useContext(WorksheetContext);
  const row = props.row
  const questions = row.questions

  return (
    <div className="row">
      {row.direction ? <div className="row-direction">{ReactHtmlParser(row.direction)}</div> : null}
      <div className="grid">
      {
        (questions || []).map((question, index) => {
          return <div key={question.id} className="question">
            <span className="question-numbering"></span>
            {
              options.showSolution ?
                (<span className="question-element">{ ReactHtmlParser(question.correct_comments_html) }</span>) :
                (<span className="question-element">{ ReactHtmlParser(question.question_text) }
                { question.question_type == 'multiple_choice_question' && question.answers ?
                  <div className="answers question-element">
                    {
                      question.answers.map((answer) => {
                        return (<div className="answer" key={answer.id}>
                          {answer.weight == 100 ? <i className="check icon green"></i> : null}
                          <span className="answer-numbering"></span>
                          <span>{ ReactHtmlParser(answer.html || answer.text)}</span>
                        </div>)
                      })
                    }
                  </div> : null
                }</span>)
            }
          </div>
        })
      }
      </div>
    </div>
  );
}
