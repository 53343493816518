import React, { useState, useEffect, useContext } from 'react';
import {Stack} from '@shopify/polaris';
import {FormProvider} from '@thinkwell/react.common';
import {EditForm, TextField, Select} from '@thinkwell/react.polaris';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import {QuestionsContext} from '../contexts/Questions'
import EditAnswers from './EditAnswers'
import EditTags from '../EditTags'

export default function EditQuestion(props) {
  const [questions, fetchQuestions, fetchQuestionsState, upsertQuestion] = useContext(QuestionsContext)

  const item = props.item || {}

  const onEditSave = (response) => {
    props.onSave && props.onSave(response)
    upsertQuestion(response)
  }

  const selectTypeOptions = [
    {label: 'Multiple Choice', value: 'multiple_choice_question'},
    {label: 'Short Answer', value: 'short_answer_question'},
    {label: 'Essay', value: 'essay_question'}
  ]

  return (
    <FormProvider data={{data: item}}  omit={['tags']} rootName="question"><EditForm
      active={props.active}
      onActive={props.onActive}
      setActiveRef={props.setActiveRef}
      onSave={onEditSave}
      url={`/questions${item.id ? `/${item.id}` : ''}.json`}
      method={item.id ? 'put' : 'post'}
      title={item.id ? item.question_name : "Add Question"}>
      <Stack vertical>
        <TextField required
          label="Name"
          id="question_name"
          name={['serialized_data', 'question_name']}
        />

        <CKTextArea required
          label="Question"
          id="question_text"
          name={['serialized_data', 'question_text']}
        />

        <CKTextArea
          label="Solution"
          id="correct_comments_html"
          name={['serialized_data', 'correct_comments_html']}
        />

        <div>
          <label className="Polaris-Label__Text">Type</label>
          <Select required
          options={selectTypeOptions}
          label={{singular: 'Type', plural: 'Type'}}
          name={['serialized_data', 'question_type']} />
        </div>

        <EditAnswers />

        <EditTags />
      </Stack>
    </EditForm></FormProvider>
  );
}
