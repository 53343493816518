import React, { useState, useRef } from 'react';
import {FormProvider} from '@thinkwell/react.common';
import {Form} from '@thinkwell/react.polaris';
import EditTags from './EditTags'
import isEqual from 'lodash/isEqual'

export default function EditTagsForm(props) {
  const editTagsSubmitRef = useRef();
  const [editTagsSaving, setEditTagsSaving] = useState(false)

  const updateTags = (tags) => {
    const tagIdsUpdated = tags.map((tag) => tag.id)
    editTagsSubmitRef.current({tag_ids: tagIdsUpdated})
  }

  return (<FormProvider data={{data: props.item}} omit={['tags']} rootName={props.rootName}>
    <Form
      submitRef={editTagsSubmitRef}
      url={props.url}
      method='put'
      onSuccess={props.onSuccess}
      onSubmitting={setEditTagsSaving}
    >
      <EditTags
        withoutHeader={true}
        onAdd={updateTags}
        onRemove={(event) => {
          if(event.detail.data.__removed) {
            const tags = event.detail.tagify.value.filter((tag) => tag.id != event.detail.data.id)
            updateTags(tags)
          }
        }}
      />
    </Form>
  </FormProvider>)

}
