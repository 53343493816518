import React, {useState, useRef} from 'react';
import {TopBar} from '@shopify/polaris';
import {SearchProvider} from '@thinkwell/react.common';
import {AppProvider} from '@thinkwell/react.polaris';
import { TagProvider } from '../contexts/Tag'
import Tag from './Tag'
import EditTag from './EditTag'
import MainFrame from '../MainFrame';

export default function Show(props) {
  const navigateToRef = useRef()
  const [search, setSearch] = useState()
  const [addTagActive, setAddTagActive] = useState(false);
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const toggleIsTagMenuOpen = () => setIsTagMenuOpen((isTagMenuOpen) => !isTagMenuOpen);

  const tagMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Tag', onAction: () => { setAddTagActive(true) }},
             {content: 'Logout', url: '/logout'}
           ]
         }
       ]}
       name="Tags"
       initials="T"
       open={isTagMenuOpen}
       onToggle={toggleIsTagMenuOpen}
     />
   );

  const onSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      navigateToRef.current(`tags?q=${encodeURIComponent(search)}`)
    }
  }

  const onSearchChange = (value) => {
    setSearch(value)
  }

  const onAddTag = (tag) => {
    navigateToRef.current(`tags/${tag.id}`)
  }

  const onDeleteTag = (tag) => {
    navigateToRef.current(`tags`)
  }

  return (
  <AppProvider>
    <EditTag
      active={addTagActive}
      onActive={(active) => { if (active != addTagActive) { setAddTagActive(active) }}}
      onSave={onAddTag}
    />
    <SearchProvider>
      <MainFrame searchPlaceHolder={props.item.thinkwell_id} onSearchChange={onSearchChange}
      onSearchKeyPress={onSearchKeyPress} navigateToRef={navigateToRef} userMenu={tagMenuMarkup}>
        <TagProvider value={props.item}>
          <Tag
            onDelete={onDeleteTag}
            navigateToRef={navigateToRef} />
        </TagProvider>
      </MainFrame>
    </SearchProvider>
  </AppProvider>)
}
