import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import {FormContext} from '@thinkwell/react.common';
import {Stack, Autocomplete, Icon} from '@shopify/polaris';
import {SearchMinor} from '@shopify/polaris-icons';
import Tagify from './Tagify'
import axios from 'axios'

export default function EditTags(props) {
  const form = useContext(FormContext)
  const tagifyRef = useRef()

  const tags = form.field('tags') || []
  const values = tags.map((tag) => tag.value)
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const addTagRef = useRef();
  const settings = {
    hooks: {
      beforeRemoveTag : ( tags ) => {
        return new Promise((resolve, reject) => {
          window.confirm("Remove " + tags[0].data.value + "?") ? resolve() : reject()
        })
      }
    }
  }

  const onTagsUpdate = (tagsUpdated) => {
    form.onData('tags')(tagsUpdated)
    form.onData('tag_ids')(tagsUpdated.map((tag) => tag.id))
  }

  const updateText = async(value) => {
    setInputValue(value);

    if (value.length < 2) {
      return
    }

    setLoading(true);
    const response = await axios({method: 'get', url: `/tags.json?q=${encodeURIComponent(value)}&limit=50`})
    // Format data into JSON
    const data = response.data;
    const resultOptions = data.items.filter(tag => !values.includes(tag.value)).map((tag) => {
      return {
        value: tag,
        label: tag.value
      }
    })
    setOptions(resultOptions);
    setLoading(false);
  }

  const updateSelection = (selectedItems) => {
    const selected = selectedItems.map((selectedItem) => {
      return options.find((option) => option.value.id == selectedItem.id)
    }).pop()
    addTagRef.current(selected.value)
    props.onAdd && props.onAdd(tagifyRef.current.value)
    setInputValue('');
    setSelectedOptions([selected]);
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search Tags"
    />
  );

  return (
    <Stack vertical>
      {
        props.withoutHeader ? null :
        <h3 className="spacer">Tags</h3>
      }
      <Stack>
        <Tagify
          tagifyRef={tagifyRef}
          addTagRef={addTagRef}
          settings={settings}
          onTagsUpdate={onTagsUpdate}
          onRemove={props.onRemove}
        />

        <Stack.Item fill>
          <Autocomplete
            allowMultiple={false}
            options={options}
            selected={selectedOptions}
            onSelect={updateSelection}
            loading={loading}
            textField={textField}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
}
