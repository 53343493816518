import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser'

export default function Header(props) {

  return (
    <div className="header">
      {ReactHtmlParser(props.html)}
    </div>
  );
}
