import React, { useContext, useState, useRef, useEffect } from 'react';
import {Stack, Icon, ResourceList, ResourceItem, Button} from '@shopify/polaris';
import {DeleteMajor} from '@shopify/polaris-icons';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import {TextField} from '@thinkwell/react.polaris';
import {FormContext} from '@thinkwell/react.common';
import EditExampleResourceItem from './EditExampleResourceItem'

export default function EditExample(props) {
  const form = useContext(FormContext)
  const [selectedItems, setSelectedItems] = useState([])

  const name = (attribute) => {
    return ['examples', props.index].concat(Array.isArray(attribute) ? attribute : [attribute])
  }

  const rows = form.field(name('rows'))

  const onDeleteRows = () => {
    const rowsUnselected = rows.filter((row) => selectedItems.indexOf(`${row.index}`) == - 1 && selectedItems.indexOf(row.id) == - 1)
    form.onData(name('rows'))(rowsUnselected.length ? rowsUnselected : [{index: 0}])
    setSelectedItems([])
  }
  const onAddQuestion = (question, index) => {
    const questions = [].concat(rows[index].questions || [], [question])
    form.onData(name(['rows', index, 'questions']))(questions)
    form.onData(name(['rows', index, 'question_ids']))(questions.map((question) => question.id))
  }
  const onAddRow = () => {
    const lastIndex = rows.map((row) => row.index).sort().pop()
    rows.push({index: (lastIndex + 1)})
    form.onData(name('rows'))(rows)
  }

  useEffect(() => {
    if (!rows || rows.length == 0) {
      form.onData(name('rows'))([{index: 0}])
    } else {
      form.onData(name('rows'))(rows)
    }
  }, [])

  const promotedBulkActions = [
    {
      content: 'Delete',
      onAction: onDeleteRows,
    },
  ];

  const renderItem = (row) => {
    return (
      <EditExampleResourceItem row={row} onAddQuestion={onAddQuestion} index={props.index} />
    );
  }

  return (
    <div className="edit-example">
      <Stack>
        <TextField required
          id={`example_header_${props.index}`}
          placeholder="Header"
          name={name('header')}
        />
      </Stack>
      {
        rows && rows.length ?
        <ResourceList
         items={rows}
         renderItem={renderItem}
         selectedItems={selectedItems}
         onSelectionChange={setSelectedItems}
         promotedBulkActions={promotedBulkActions}
         resourceName={ {singular: 'row', plural: 'rows'} }
         alternateTool={<Button onClick={onAddRow}>Add Row</Button>}
        /> : null
      }
    </div>
  );
}
