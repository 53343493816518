import React, { useRef } from 'react';
import { Util, FormProvider} from '@thinkwell/react.common';
import { AppProvider, TextField, Form } from '@thinkwell/react.polaris';
import { Card, Page } from '@shopify/polaris';

export default function Login(props) {
  const submitRef = useRef()
  const after_sign_in = Util.getParam(window.location.href, 'after_sign_in')
  return (<AppProvider><div>
    <FormProvider rootName="user">
    <Page narrowWidth>
      <Card title="Login" sectioned>
      <Form
        url={after_sign_in ? `/login.json?after_sign_in=${after_sign_in}` : '/login.json'}
        method="post"
        submitButton="Sign In"
        submitRef={submitRef}
      >
        <TextField
          label="Email"
          id="username"
          name="crowd_username"
        />
        <TextField
          label="Password"
          id="password"
          name="password"
          type="password"
          onEnterPressed={() => submitRef.current()}
        />
    </Form>
    </Card>
    </Page>
    </FormProvider>
  </div></AppProvider>)
}
