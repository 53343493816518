import React, { useContext } from 'react';
import {FormContext} from '@thinkwell/react.common';
import { useDrop } from 'react-dnd';

export default function EditQuestionDrop(props) {
  const form = useContext(FormContext)

  const sameExample = (item) => item.exampleIndex == props.exampleIndex
  const sameRow = (item) => item.rowIndex == props.rowIndex
  const sameQuestion = (item) => item.id == props.afterQuestionId || item.id == props.beforeQuestionId

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'question',
    drop: (item) => {
      const questionId = item.id

      // remove question from previous parent row
      const draggedFromQuestions = form.field(['examples', item.exampleIndex, 'rows', item.rowIndex, 'questions'])
      const draggedFromIndex = draggedFromQuestions.findIndex((q) => q.id == questionId)
      const question = draggedFromQuestions.splice(draggedFromIndex, 1).pop()
      let droppedToQuestions = draggedFromQuestions;
      // only save if different example or row
      if(!sameExample(item) || !sameRow(item)) {
        form.onData(['examples', item.exampleIndex, 'rows', item.rowIndex, 'questions'])(draggedFromQuestions)
        form.onData(['examples', item.exampleIndex, 'rows', item.rowIndex, 'question_ids'])(draggedFromQuestions.map((q) => q.id))
        droppedToQuestions = form.field(['examples', props.exampleIndex, 'rows', props.rowIndex, 'questions']) || []
      }

      // add question to new parent row
      const index = props.afterQuestionId ? droppedToQuestions.findIndex((q) => q.id == props.afterQuestionId) + 1 : 0
      droppedToQuestions.splice(index, 0, question)
      form.onData(['examples', props.exampleIndex, 'rows', props.rowIndex, 'questions'])(droppedToQuestions)
      form.onData(['examples', props.exampleIndex, 'rows', props.rowIndex, 'question_ids'])(droppedToQuestions.map((q) => q.id))
    },
    canDrop: (item, monitor) => {
      // don't allow drop for item at previous index
      if(sameExample(item) && sameRow(item) && sameQuestion(item)) {
        return false
      }

      return true
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  });

  const isActive = isOver && canDrop;
  let border = 'none';
  let backgroundColor = 'transparent';
  if (isActive) {
    backgroundColor = 'darkgreen';
  }
  if (canDrop) {
    border = '1px solid black'
  }

  return (
    <div ref={drop} className="edit-question-drop" style={{ backgroundColor, border }}>
    </div>
  );
}
