import React, { useState } from 'react';

import {Items, useFetch} from '@thinkwell/react.common';
const WorksheetsContext = React.createContext([{}, () => {}, {}, () => {}, () => {}, () => {}]);

const WorksheetsProvider = (props) => {
  const [items, setItems, upsertItem, getItem, deleteItem] = Items(useState(props && props.value || []))
  const [fetchWorksheetsState, fetchWorksheets] = useFetch({onSuccess: setItems})

  return (
    <WorksheetsContext.Provider value={[items, fetchWorksheets, fetchWorksheetsState, upsertItem, getItem, deleteItem]}>
      {props.children}
    </WorksheetsContext.Provider>
  );
};

export { WorksheetsContext, WorksheetsProvider}
