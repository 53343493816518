import React, { useState, useEffect, useContext, useRef } from 'react';
import {TextStyle, Layout, Card, Stack, Link, InlineError, Button, Icon} from '@shopify/polaris';
import {TickMinor} from '@shopify/polaris-icons';
import ReactHtmlParser from 'react-html-parser'
import EditQuestion from './EditQuestion';
import EditTagsForm from '../EditTagsForm';
import { QuestionContext } from '../contexts/Question'
import {useReducerRequest} from '@thinkwell/react.common';
import compact from 'lodash/compact'

export default function Question(props) {
  const [question, setQuestion] = useContext(QuestionContext);
  const [editing, setEditing] = useState(false)

  const [stateClone, onClone] = useReducerRequest('post', {onSuccess: (payload) => {
    props.onClone && props.onClone(payload.data)
  }})

  const clone = () => {
    onClone(`/questions/${question.id}/clone`)
  }

  const onFoundryClick = () => {
    window.open('http://services.thinkwell.com/questionauthoring/preview/all.cfm?usid=-1&qid=' + question.thinkwell_id, 'previewWindow','width=800,height=600');
  }

  return (
    <div className="question">
      <Layout>
        <Layout.Section primary>
          { !editing ?
          (<Card>
            <Card.Header title={ReactHtmlParser(question.serialized_data.question_name)}>
              <Button plain onClick={() => props.navigateToRef.current('questions', {withContext: 'url'}) }>Back to Questions</Button>
              <Button plain onClick={() => setEditing(true) }>Edit</Button>
              <Button plain loading={stateClone.requesting} onClick={clone}>Clone</Button>
              <Button plain onClick={onFoundryClick}>Foundry</Button>
              {stateClone.error ? <InlineError message={stateClone.error} fieldID="cloneErrorFieldID" /> : null}
            </Card.Header>
            <Card.Section>
              <div className="question-element">{ ReactHtmlParser(question.serialized_data.question_text) }</div>
            </Card.Section>
            <Card.Section title="Solution">
              <div className="question-element">
                {ReactHtmlParser(question.serialized_data.correct_comments_html)}
                <br style={{clear: "both"}} />
              </div>
            </Card.Section>
            <Card.Section title="Answers">
              <div className="question-element">
              {
                (question.serialized_data.answers || []).map((answer) => {
                  return (<div className="answer" key={answer.id}>
                    {answer.weight == 100 ? <span className="check"><Icon source={TickMinor} color="success" /></span> : null}
                    <span>{ ReactHtmlParser(answer.html || answer.text)}</span>
                  </div>)
                })
              }
              </div>
            </Card.Section>
            {
              question.worksheets && question.worksheets.length > 0 ?
                <Card.Section title="Worksheets">
                {
                  question.worksheets.map((worksheet) => {
                    return (<div key={worksheet.id}><a href={`/worksheets/${worksheet.id}`}>{worksheet.title}</a></div>)
                  })
                }
                </Card.Section>
              : null
            }
            <Card.Section title="Tags">
              <EditTagsForm
                onSuccess={setQuestion}
                item={question}
                rootName="question"
                url={`/questions${`/${question.id}`}.json`}
              />
            </Card.Section>
          </Card>) : null }
          <EditQuestion
            active={editing}
            item={question}
            onSave={setQuestion}
            onActive={(active) => { if (active != editing) { setEditing(active) } }}
          />
        </Layout.Section>
      </Layout>

    </div>
  );
}
