import React, { useState, useEffect, useContext } from 'react';
import {TextStyle, Layout, Card, Stack, Link, Button} from '@shopify/polaris';
import ReactHtmlParser from 'react-html-parser'
import EditTemplate from './EditTemplate';
import { TemplateContext } from '../contexts/Template'
import compact from 'lodash/compact'

export default function Template(props) {
  const [template, setTemplate] = useContext(TemplateContext);
  const [editing, setEditing] = useState(false)

  return (
    <div>
      <Layout>
        <Layout.Section primary>
          <EditTemplate
            active={editing}
            item={template}
            onSave={setTemplate}
            onActive={(active) => { if (active != editing) { setEditing(active) } }}
          />
          { !editing ?
            (<Card>
              <Card.Header title={template.title}>
                <Button plain onClick={() => props.navigateToRef.current('templates', {withContext: 'url'}) }>Back to Templates</Button>
                <button className="Polaris-Link" onClick={() => setEditing(true)}>Edit</button>
              </Card.Header>
              <Card.Section>
                { template.header_html ? <h2>Header</h2> : null }
                { template.header_html ? <div>{ReactHtmlParser(template.header_html)}</div> : null }

                { template.footer_html ? <h2>Footer</h2> : null }
                { template.footer_html ? <div>{ReactHtmlParser(template.footer_html)}</div> : null }

                { template.styles ? <h2>Styles</h2> : null }
                { template.styles ? <div style={{whiteSpace: "pre-line"}}>{template.styles}</div> : null }
              </Card.Section>
              {
                template.metatags && template.metatags.length > 0 ?
                  <Card.Section title="Metatags">
                  {
                    template.metatags.map((metatag) => {
                      return (<div key={metatag.name}>{metatag.name} : {metatag.content}</div>)
                    })
                  }
                  </Card.Section>
                : null
              }
              {
                template.worksheets && template.worksheets.length > 0 ?
                  <Card.Section title="Worksheets">
                  {
                    template.worksheets.map((worksheet) => {
                      return (<div key={worksheet.id}><a href={`/worksheets/${worksheet.id}`}>{worksheet.title}</a></div>)
                    })
                  }
                  </Card.Section>
                : null
              }
              {
                template.tags && template.tags.length > 0 ?
                  <Card.Section title="Tags">
                  {
                    template.tags.map((tag) => {
                      return (<div key={tag.id}><a href={`/tags/${tag.id}`}>{tag.value}</a></div>)
                    })
                  }
                  </Card.Section>
                : null
              }
            </Card>) : null }
        </Layout.Section>
      </Layout>
    </div>
  );
}
