import React from 'react';
import {PagingProvider, SearchProvider} from '@thinkwell/react.common';
import {AppProvider} from '@thinkwell/react.polaris';
import { TemplatesProvider } from '../contexts/Templates'
import Templates from './Templates'

export default function Index(props) {
  return ( <AppProvider>
    <SearchProvider search={props.query}>
    <PagingProvider previous_page_info={props.previous_page_info} next_page_info={props.next_page_info} page={props.page}>
    <TemplatesProvider value={props.items}>
      <Templates />
    </TemplatesProvider>
    </PagingProvider>
    </SearchProvider></AppProvider>)

}
