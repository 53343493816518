import React, { useState, useEffect, useContext, useRef } from 'react';
import {ResourceList as ResourceListShopify, Avatar, TextStyle, Stack, Link, TopBar, Icon} from '@shopify/polaris';
import {EditMinor} from '@shopify/polaris-icons';
import {SearchContext} from '@thinkwell/react.common';
import {ResourceList} from '@thinkwell/react.polaris';
import EditTag from './EditTag';
import MainFrame from '../MainFrame';
import { TagsContext } from '../contexts/Tags'
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy'

const formatDate = (dateStr) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(dateStr))
};

export default function Tags(props) {
  const [tags, fetchTags, fetchTagsState] = useContext(TagsContext);
  const [search, setSearch] = useContext(SearchContext);

  const navigateToRef = useRef()
  const [addTagActive, setAddTagActive] = useState(false);
  const [editTagItem, setEditTagItem] = useState()
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const toggleIsTagMenuOpen = () => setIsTagMenuOpen((isTagMenuOpen) => !isTagMenuOpen);

  const tagsSorted = sortBy(tags, ['value']);

  const onSearch = (params) => {
    fetchTags(`/tags.json`, params)
  }

  const delayedSearch = useRef(debounce(onSearch, 1000));

  const onSearchChange = (value) => {
    setSearch(value)
    delayedSearch.current({query: value})
  }

  const tagMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Tag', onAction: () => { setEditTagItem(null); setAddTagActive(true) }},
             {content: 'Logout', url: '/logout'}
           ]
         }
       ]}
       name="Tags"
       initials="T"
       open={isTagMenuOpen}
       onToggle={toggleIsTagMenuOpen}
     />
   );

  const renderTag = (item) => {
    const {value} = item;
    return (
      <ResourceListShopify.Item
        id={item.id}
        accessibilityLabel={`View details for ${value}`}
        onClick={() => navigateToRef.current(`tags/${item.id}`, {withContext: true})}
        shortcutActions={[{content: <Icon source={EditMinor} />, onAction: () => { setEditTagItem(item); setAddTagActive(true) }}]}
      >
        <Stack>
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{value}</TextStyle>
            </h3>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="subdued">{formatDate(item.updated_at)}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceListShopify.Item>
    );
  };

  return (
    <div>
    <EditTag
      item={editTagItem}
      active={addTagActive}
      onActive={(active) => { if (active != addTagActive) { setAddTagActive(active) }}}
    />
    <MainFrame onSearchChange={onSearchChange} searchPlaceHolder="Search tags"
    userMenu={tagMenuMarkup} navigateToRef={navigateToRef}>
      <ResourceList withoutSort items={tagsSorted} fetchItems={fetchTags} fetchItemsState={fetchTagsState}
      url="/tags.json" renderItem={renderTag}
      resourceName={ {singular: 'tag', plural: 'tags'} }></ResourceList>
    </MainFrame>
  </div>);
}
