import React, { useState, useEffect, useContext } from 'react';
import {TextStyle, Layout, Card, Stack, Link, List, Icon, Button, Spinner} from '@shopify/polaris';
import {EditMinor} from '@shopify/polaris-icons';
import ReactHtmlParser from 'react-html-parser'
import Example from './Example';
import Header from './Header';
import Footer from './Footer';
import { WorksheetContext } from '../contexts/Worksheet'

export default function Worksheet(props) {
  const [worksheet, setWorksheet, options] = useContext(WorksheetContext);
  const [editing, setEditing] = useState(false)
  const [editingTemplate, setEditingTemplate] = useState(false)

  const EditWorksheet = React.lazy(() => import('./EditWorksheet'));
  const EditTemplate = React.lazy(() => import('../templates/EditTemplate'));
  const EditTagsForm = React.lazy(() => import('../EditTagsForm'));

  const onSave = (worksheet) => {
    window.scrollTo(0,0)
    document.querySelector('iframe').contentWindow.scrollTo(0,0)
    setWorksheet(worksheet)
  }

  if (props.onSaveRef) {
    props.onSaveRef.current = onSave
  }

  return (
    <div className={options.showSolution ? 'answerkey' : 'worksheet'}>
      { options.readOnly && !options.hideHeader && worksheet.template && worksheet.template.header_html ? <Header html={worksheet.template.header_html} /> : null }
      <Layout>
        <Layout.Section primary>
          {
            editing ?
            <React.Suspense fallback={<></>}>
              <EditWorksheet
              active={editing}
              item={worksheet}
              onSave={onSave}
              onActive={(active) => { if (active != editing) { setEditing(active) } }}
              />
            </React.Suspense>
            : null
          }
          {
            editingTemplate ?
            <React.Suspense fallback={<></>}>
              <EditTemplate
              active={editingTemplate}
              item={worksheet.template}
              onSave={(template) => { worksheet.template = template; onSave(worksheet); }}
              onActive={(active) => { if (active != editingTemplate) { setEditingTemplate(active) } }}
              />
            </React.Suspense>
            : null
          }
          {
            !editing && !editingTemplate ?
            (<Card>
              {
                options.readOnly ? <Card.Header></Card.Header> :
                <Card.Header title={worksheet.title}>
                  <Stack>
                    <Button plain onClick={() => props.navigateToRef.current('worksheets', {withContext: 'url'}) }>Back to Worksheets</Button>
                    <Button plain onClick={() => { setEditing(true) }}>Edit</Button>
                    <a target="_blank" className="Polaris-Button Polaris-Button--plain" href={`/worksheets/${worksheet.id}/${worksheet.title}`}>HTML Worksheet</a>
                    <a target="_blank" className="Polaris-Button Polaris-Button--plain" href={`${options.pdfgenHost}/pdf/${worksheet.id}?title=${worksheet.title}&template=worksheets/display`}>PDF Worksheet</a>
                    <a target="_blank" className="Polaris-Button Polaris-Button--plain" href={`/answer_keys/${worksheet.id}/${worksheet.title}`}>HTML Answer Key</a>
                    <a target="_blank" className="Polaris-Button Polaris-Button--plain" href={`${options.pdfgenHost}/pdf/${worksheet.id}?title=${worksheet.title}&template=worksheets/answer_key`}>PDF Answer Key</a>
                  </Stack>
                </Card.Header>
              }
              <Card.Section />
              {
                options.readOnly ?
                  <Card.Section>
                    <h1 className="worksheet-header">{worksheet.main_header}</h1>
                    <h2 className="worksheet-subheader">{ ReactHtmlParser(worksheet.sub_header) }</h2>
                    <ol className="examples">
                    {
                      (worksheet.examples || []).map((example) => {
                        return (<Example key={example.id} example={example} />)
                      })
                    }
                    </ol>
                  </Card.Section> : null
              }
            </Card>) : null
          }
        </Layout.Section>
      </Layout>
      {
        !options.readOnly && !editing && !editingTemplate ?
        <div className="iframe">
          <span className="loading image" title="spinner"><Spinner color="teal" size="large" /></span>
          <iframe
            className="display"
            frameBorder="0"
            height="100%"
            width="100%"
            src={`/worksheets/${worksheet.id}/${worksheet.title}?t=${Date.now()}`}
            onLoad={() => document.querySelector('.iframe .loading').style.display = 'none'}
          />
        </div> : null
      }
        <Card>
        {
          !options.readOnly && !editing && !editingTemplate && worksheet.template ?
            <Card.Section title="Template">
              <Stack><a href={`/templates/${worksheet.template.id}`}>{ReactHtmlParser(worksheet.template.title)}</a><Button icon={EditMinor} onClick={() => setEditingTemplate(true)} /></Stack>
            </Card.Section>
          : null
        }
        {
          !options.readOnly && !editing && !editingTemplate ?
            (<React.Suspense fallback={<></>}>
              <Card.Section title="Tags">
                <EditTagsForm
                  onSuccess={setWorksheet}
                  item={worksheet}
                  rootName="worksheet"
                  url={`/worksheets${`/${worksheet.id}`}.json`}
                />
            </Card.Section>
          </React.Suspense>)
          : null
        }
        </Card>
      { options.readOnly && !options.hideFooter && worksheet.template && worksheet.template.footer_html ? <Footer html={worksheet.template.footer_html} /> : null }
    </div>
  );
}
