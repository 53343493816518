import React, { useContext } from 'react';
import {Stack, RadioButton, Icon} from '@shopify/polaris';
import {DeleteMajor} from '@shopify/polaris-icons';
import {FormContext} from '@thinkwell/react.common';
import {TextField} from '@thinkwell/react.polaris';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import ReactHtmlParser from 'react-html-parser'
import compact from 'lodash/compact'

export default function EditAnswer(props) {
  const form = useContext(FormContext)

  const answer = props.answer

  const name = (attribute) => {
    return ['serialized_data', 'answers', props.index, attribute]
  }
  const handleChange = (value) => {
    props.onChecked && props.onChecked(answer.id)
  }
  const onDelete = () => {
    props.onDelete && props.onDelete(props.index)
  }

  const weight = parseInt(form.field(name('weight'))) || 0
  const questionType = form.field(['serialized_data', 'question_type'])
  const isChoiceType = !questionType || ["multiple_choice_question"].indexOf(questionType) != -1

  return (
    <div className="answer" key={answer.id}>
      <Stack>
        { isChoiceType ?
          [<RadioButton
            key={`weight_${answer.id}`}
            checked={weight == 100}
            name="weight"
            onChange={handleChange}
            value={answer.id}
          />,
          <Stack.Item fill key={`html_${answer.id}`}>
            <CKTextArea
            id={`answer_choice_html_${props.index}`}
            name={!!answer.html ? name('html') : name('text')}
            />
          </Stack.Item>,
          <Stack.Item fill key={`comment_${answer.id}`}>
            <CKTextArea
            id={`answer_choice_comment_${props.index}`}
            name={name('comment')}
            />
          </Stack.Item>] :
          (<TextField
            id={`answer_choice_text_${props.index}`}
            name={name('text')}
          />)
        }
        <div className="one wide column right aligned delete-answer-choice-icon" onClick={onDelete}><Icon source={DeleteMajor} /></div>
      </Stack>
    </div>
  );
}
