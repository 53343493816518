import React, { useState, useEffect, useContext } from 'react';
import {Stack, ResourceList, ResourceItem, Button} from '@shopify/polaris';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import {FormContext} from '@thinkwell/react.common';
import {TextField} from '@thinkwell/react.polaris';
import {TemplatesContext} from '../contexts/Templates'
import EditTags from '../EditTags'

export default function EditMetatags(props) {
  const [selectedItems, setSelectedItems] = useState([])
  const form = useContext(FormContext)

  const metatags = form.field('metatags') || []

  const onDeleteMetatags = () => {
    const metatagsUnselected = metatags.filter((metatag, index) => selectedItems.indexOf(index) == - 1)
    form.onData('metatags')(metatagsUnselected)
    setSelectedItems([])
  }
  const onAddMetatag = () => {
    const count = metatags.length
    metatags.push({})
    form.onData('metatags')(metatags)
  }

  useEffect(() => {
    if (metatags) {
      form.onData('metatags')(metatags)
    }
  }, [])

  const promotedBulkActions = [
    {
      content: 'Delete',
      onAction: onDeleteMetatags,
    },
  ];

  const renderItem = (metatag, id, index) => {
    return (
      <ResourceItem
        id={index}
        accessibilityLabel={`View details for ${metatag.name}`}
      >
        <Stack>
          <TextField required
            id={`metatag_name_${index}`}
            placeholder="Name"
            name={['metatags', index, 'name']}
          />
          <TextField
            id={`metatag_content_${index}`}
            placeholder="Content"
            name={['metatags', index, 'content']}
          />
        </Stack>
      </ResourceItem>
    );
  }

  return (<Stack vertical>
      <h3 className="spacer">Metatags</h3>
      <ResourceList
       items={metatags}
       renderItem={renderItem}
       selectedItems={selectedItems}
       onSelectionChange={setSelectedItems}
       promotedBulkActions={promotedBulkActions}
       resourceName={ {singular: 'metatag', plural: 'metatags'} }
       emptyState={<Button onClick={onAddMetatag}>Add Metatag</Button>}
       alternateTool={<Button onClick={onAddMetatag}>Add Metatag</Button>}
      />
    </Stack>
  );
}
