import React, { useState } from 'react';

const WorksheetContext = React.createContext();

const WorksheetProvider = (props) => {
  const [worksheet, setWorksheet] = useState(props.value)
  const [options, setOptions] = useState(props.options || {})

  return (
    <WorksheetContext.Provider value={[worksheet, setWorksheet, options]}>
      {props.children}
    </WorksheetContext.Provider>
  );
};

export { WorksheetContext, WorksheetProvider}
