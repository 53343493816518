import React, { useState } from 'react';

const TemplateContext = React.createContext();

const TemplateProvider = (props) => {
  const [template, setTemplate] = useState(props.value)

  return (
    <TemplateContext.Provider value={[template, setTemplate]}>
      {props.children}
    </TemplateContext.Provider>
  );
};

export { TemplateContext, TemplateProvider}
