import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import {FormContext} from '@thinkwell/react.common';
import {Stack, Autocomplete, Icon} from '@shopify/polaris';
import {SearchMinor} from '@shopify/polaris-icons';
import Tags from "@yaireo/tagify/dist/react.tagify"
import sortBy from 'lodash/sortBy'

export default function Tagify(props) {
  const form = useContext(FormContext)
  const tagifyRef = props.tagifyRef
  const settings = Object.assign({
    editTags: false,
    autoComplete: {
      enabled: false
    }
  }, props.settings || {})

  const name = props.name || 'tags'
  const tags = form.field(name) || []

  props.addTagRef.current = (tag) => {
    tagifyRef.current.addTags([tag])
  }

  useEffect(() => {
    tagifyRef.current.addTags(sortBy(tags, ['value']))
  }, [])

  return (
    <Tags
      tagifyRef={tagifyRef}
      settings={settings}
      onAdd={props.onAdd}
      onRemove={props.onRemove}
      onChange={ e => {
        props.onTagsUpdate(e.detail.tagify.value, e)
      }}
    />
  );
}
