import React, { useState } from 'react';

import {Items, useFetch} from '@thinkwell/react.common';
const QuestionsContext = React.createContext([{}, () => {}, {}, () => {}, () => {}, () => {}]);

const QuestionsProvider = (props) => {
  const [items, setItems, upsertItem, getItem, deleteItem] = Items(useState(props && props.value || []))
  const [fetchQuestionsState, fetchQuestions] = useFetch({onSuccess: setItems})

  return (
    <QuestionsContext.Provider value={[items, fetchQuestions, fetchQuestionsState, upsertItem, getItem, deleteItem]}>
      {props.children}
    </QuestionsContext.Provider>
  );
};

export { QuestionsContext, QuestionsProvider}
