import React, { useState, useRef } from 'react'
import {Navigation} from '@shopify/polaris';
import {MainFrame as MainFrameBase} from '@thinkwell/react.polaris';
import {QuestionMarkMajor, HashtagMajor, GrammarMajor} from '@shopify/polaris-icons';

export default function MainFrame(props) {
  const navigateToRef = props.navigateToRef || useRef()

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            label: 'Questions',
            onClick: () => navigateToRef.current('questions'),
          },
          {
            label: 'Worksheets',
            onClick: () => navigateToRef.current('worksheets'),
          },
          {
            label: 'Tags',
            onClick: () => navigateToRef.current('tags'),
          },
          {
            label: 'Templates',
            onClick: () => navigateToRef.current('templates'),
          }
        ]}
      />
    </Navigation>
  );

  return (
    <MainFrameBase
      navigation={navigationMarkup}
      navigateToRef={navigateToRef}
      onSearchChange={props.onSearchChange}
      onSearchKeyPress={props.onSearchKeyPress}
      searchPlaceHolder={props.searchPlaceHolder}
      loading={props.loading}
      userMenu={props.userMenu}
      withoutSearch={props.withoutSearch}
    >
      {props.children}
    </MainFrameBase>
  );
}
