import React, { useContext } from 'react';
import {Icon, Stack, Tooltip} from '@shopify/polaris';
import {DeleteMajor, DragHandleMinor} from '@shopify/polaris-icons';
import ReactHtmlParser from 'react-html-parser'
import { useDrag } from 'react-dnd';

export default function EditQuestion(props) {
  const question = props.question
  const type = 'question'
  const id = question.id

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: type,
    item: {id: id, exampleIndex: props.exampleIndex, rowIndex: props.rowIndex},
    canDrag: (monitor) => true,
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      }
    }
    ,
  }), [id, type, props.exampleIndex, props.rowIndex]);

  return (
    <div ref={dragPreview} className={`edit-question ${isDragging ? 'dragging' : ''}`}>
      <span ref={drag} className="drag-icon"><Icon source={DragHandleMinor} /></span>
      <Tooltip content={ReactHtmlParser(question.question_text)}>
        <a href={`/questions/${question.id}`} target="_blank">{ReactHtmlParser(question.question_name)}</a>
      </Tooltip>
      <span className="delete-icon" onClick={props.onDelete}><Icon source={DeleteMajor} /></span>
    </div>
  );
}
