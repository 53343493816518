import React, { useState, useEffect, useContext, useRef } from 'react';
import {ResourceList as ResourceListShopify, Avatar, TextStyle, Stack, Link, TopBar, Icon} from '@shopify/polaris';
import {EditMinor} from '@shopify/polaris-icons';
import {SearchContext} from '@thinkwell/react.common';
import {ResourceList} from '@thinkwell/react.polaris';
import EditWorksheet from './EditWorksheet';
import MainFrame from '../MainFrame';
import { WorksheetsContext } from '../contexts/Worksheets'
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';

const formatDate = (dateStr) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(dateStr))
};

export default function Worksheets(props) {
  const [worksheets, fetchWorksheets, fetchWorksheetsState] = useContext(WorksheetsContext);
  const [search, setSearch] = useContext(SearchContext);

  const navigateToRef = useRef()
  const [addWorksheetActive, setAddWorksheetActive] = useState(false);
  const [isWorksheetMenuOpen, setIsWorksheetMenuOpen] = useState(false);
  const [editWorksheetItem, setEditWorksheetItem] = useState()
  const toggleIsWorksheetMenuOpen = () => setIsWorksheetMenuOpen((isWorksheetMenuOpen) => !isWorksheetMenuOpen);

  const onSearch = (params) => {
    fetchWorksheets(`/worksheets.json`, params)
  }

  const delayedSearch = useRef(debounce(onSearch, 1000));

  const onSearchChange = (value) => {
    setSearch(value)
    delayedSearch.current({query: value})
  }

  const worksheetMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Worksheet', onAction: () => { setEditWorksheetItem(null); setAddWorksheetActive(true) }},
             {content: 'Logout', url: '/logout'}
           ]
         }
       ]}
       name="Worksheets"
       initials="WS"
       open={isWorksheetMenuOpen}
       onToggle={toggleIsWorksheetMenuOpen}
     />
   );

  const renderWorksheet = (item) => {
    const {title} = item;
    return (
      <ResourceListShopify.Item
        id={item.id}
        accessibilityLabel={`View details for ${title}`}
        onClick={() => { navigateToRef.current(`worksheets/${item.id}`, {withContext: true}) }}
        shortcutActions={[{content: <Icon source={EditMinor} />, onAction: () => { setEditWorksheetItem(item); setAddWorksheetActive(true) }}]}
      >
        <Stack>
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{title}</TextStyle>
            </h3>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="subdued">{formatDate(item.updated_at)}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceListShopify.Item>
    );
  };

  const onAddWorksheet = (worksheet) => {
    if (!editWorksheetItem) {
      navigateToRef.current(`worksheets/${worksheet.id}`)
    }
  }

  return (
    <div>
    <MainFrame onSearchChange={onSearchChange} searchPlaceHolder="Search worksheets"
    userMenu={worksheetMenuMarkup} navigateToRef={navigateToRef}>
      { addWorksheetActive ?
        <EditWorksheet
          item={editWorksheetItem}
          active={addWorksheetActive}
          onActive={(active) => { if (active != addWorksheetActive) { setAddWorksheetActive(active) }}}
          onSave={onAddWorksheet}
        /> :
        <ResourceList withoutSort items={worksheets} fetchItems={fetchWorksheets} fetchItemsState={fetchWorksheetsState}
        url="/worksheets.json" renderItem={renderWorksheet}
        resourceName={ {singular: 'worksheet', plural: 'worksheets'} }></ResourceList>
      }
    </MainFrame>
  </div>);
}
