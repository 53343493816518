import React, { useEffect, useContext, useState } from 'react';
import {FormContext} from '@thinkwell/react.common';
import {Stack, Autocomplete, Icon, Modal, Link, Heading, TextContainer, Button, Popover} from '@shopify/polaris';
import {SearchMinor, MobileCancelMajor} from '@shopify/polaris-icons';
import ReactHtmlParser from 'react-html-parser'
import pick from 'lodash/pick'
import axios from 'axios'

export default function AddQuestion(props) {
  const form = useContext(FormContext)
  const [addQuestionActive, setAddQuestionActive] = useState()
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const questions = form.field(['examples', props.exampleIndex, 'rows', props.rowIndex, 'questions'])
  const questionNames = (questions || []).map((question) => question && question.question_name)
  const tags = form.field('tags')

  const updateOptions = async(value) => {
    const response = await axios({method: 'get', url: `/questions.json?q=${encodeURIComponent(value)}&limit=50`})
    const data = response.data;
    const resultOptions = data.items.filter((question) => {
      return !questionNames.includes(question.question_name)
    }).map((question) => {
      return {
        value: pick(question, ['id', 'question_name', 'question_text']),
        label: ReactHtmlParser(question.question_name)
      }
    })
    setOptions(resultOptions);
    setLoading(false);
  }

  const updateText = (value) => {
    setInputValue(value);

    if (value.length < 2) {
      return
    }

    setLoading(true);
    updateOptions(value)
  }

  const updateSelection = (selectedItems) => {
    const selected = selectedItems.map((selectedItem) => {
      return options.find((option) => option.value.id == selectedItem.id)
    }).pop()
    setInputValue(selected && selected.label);
    props.onAdd(selected && selected.value, props.rowIndex)
    setAddQuestionActive(false)
    setSelectedOptions([selected]);
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      onFocus={() => setAddQuestionActive(true)}
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Add Question"
      autoFocus={true}
    />
  );

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{textAlign: 'center'}}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );

  useEffect(() => {
    if (addQuestionActive && tags && tags.length) {
      const value = tags.map((tag) => tag.value).join('|')
      updateOptions(value)
    }
  }, [addQuestionActive])

  return (
    <div>
      <Autocomplete
        allowMultiple={false}
        options={options}
        selected={selectedOptions}
        onSelect={updateSelection}
        emptyState={emptyState}
        loading={loading}
        textField={textField}
        preferredPosition="below"
      />
    </div>
  );
}
