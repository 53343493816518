import React, {useState} from 'react';
import {AppProvider} from '@shopify/polaris';
import { WorksheetProvider } from '../contexts/Worksheet'
import Worksheet from './Worksheet'

export default function AnswerKey(props) {
  return (
  <AppProvider>
    <WorksheetProvider value={props.item} options={{readOnly: true, hideHeader: props.hideHeader, hideFooter: props.hideFooter, showSolution: true }}>
      <Worksheet />
    </WorksheetProvider>
  </AppProvider>)
}
