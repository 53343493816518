import React, { useContext, useState, useRef, useEffect } from 'react';
import {Stack, Icon, ResourceList, ResourceItem, Button, Popover} from '@shopify/polaris';
import AddQuestion from './AddQuestion'
import EditRow from './EditRow'

export default function EditExampleResourceItem(props) {
  const index = props.row.index
  const id = props.row.id || index+''

  return (
    <ResourceItem
      id={id}
      accessibilityLabel={`View details for row ${id}`}
    >
      <Stack wrap={false}>
        <Stack.Item fill>
          <EditRow key={id} row={props.row} exampleIndex={props.index} index={index} />
        </Stack.Item>
        <Stack.Item>
          <AddQuestion onAdd={props.onAddQuestion} exampleIndex={props.index} rowIndex={index} />
        </Stack.Item>
      </Stack>
    </ResourceItem>
  );
}
