import React, { useContext, useState } from 'react';
import {Stack, List} from '@shopify/polaris';
import { WorksheetContext } from '../contexts/Worksheet'
import ReactHtmlParser from 'react-html-parser'
import Row from './Row'

export default function Example(props) {
  const [worksheet, setWorksheet, options] = useContext(WorksheetContext);
  const example = props.example
  const rows = example.rows || []

  return (
    <div className="example">
      <h3 className="example-header">{example.header}</h3>
      <div style={{marginLeft: '10px'}} className="questions">
        <Stack vertical>
        {
          rows.map((row, index) => {
            return <Row row={row} key={row.index} />
          })
        }
        </Stack>
      </div>
    </div>
  );
}
