import React, {useState, useRef} from 'react';
import {TopBar} from '@shopify/polaris';
import {SearchProvider} from '@thinkwell/react.common';
import {AppProvider} from '@thinkwell/react.polaris';
import { WorksheetProvider } from '../contexts/Worksheet'
import Worksheet from './Worksheet'
import EditWorksheet from './EditWorksheet'
import MainFrame from '../MainFrame';

export default function Show(props) {
  const navigateToRef = useState()
  const [search, setSearch] = useState()
  const [addWorksheetActive, setAddWorksheetActive] = useState(false);
  const [isWorksheetMenuOpen, setIsWorksheetMenuOpen] = useState(false);
  const toggleIsWorksheetMenuOpen = () => setIsWorksheetMenuOpen((isWorksheetMenuOpen) => !isWorksheetMenuOpen);

  const worksheetMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Worksheet', onAction: () => { setAddWorksheetActive(true) }},
             {content: 'Logout', url: '/logout'}
           ]
         }
       ]}
       name="Worksheets"
       initials="WS"
       open={isWorksheetMenuOpen}
       onToggle={toggleIsWorksheetMenuOpen}
     />
   );

  const onSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      navigateToRef.current(`worksheets?q=${encodeURIComponent(search)}`)
    }
  }

  const onSearchChange = (value) => {
    setSearch(value)
  }

  const onAddWorksheet = (worksheet) => {
    navigateToRef.current(`worksheets/${worksheet.id}`)
  }

  return (
  <AppProvider>
    <SearchProvider>
      <MainFrame searchPlaceHolder={props.item.thinkwell_id} onSearchChange={onSearchChange}
      onSearchKeyPress={onSearchKeyPress} navigateToRef={navigateToRef} userMenu={worksheetMenuMarkup}>
        <WorksheetProvider value={props.item} options={{pdfgenHost: props.pdfgenHost}}>
          { addWorksheetActive ?
            <EditWorksheet
              active={addWorksheetActive}
              onActive={(active) => { if (active != addWorksheetActive) { setAddWorksheetActive(active) }}}
              onSave={onAddWorksheet}
            /> :
            <Worksheet
              onSaveRef={props.onSaveRef}
              navigateToRef={navigateToRef}
            />
          }
        </WorksheetProvider>
      </MainFrame>
    </SearchProvider>
  </AppProvider>)
}
