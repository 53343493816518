import React, { useEffect, useContext, useState } from 'react';
import {FormContext} from '@thinkwell/react.common';
import {Stack, List, Button, ResourceList, ResourceItem} from '@shopify/polaris';
import EditExample from './EditExample'
import { useDragLayer } from 'react-dnd'

export default function EditExamples(props) {
  const [selectedItems, setSelectedItems] = useState([])
  const form = useContext(FormContext)

  const examples = form.field('examples') || []

  const onDeleteExamples = () => {
    const examplesUnselected = examples.filter((example) => selectedItems.indexOf(example.id) == - 1)
    form.onData('examples')(examplesUnselected.length ? examplesUnselected : [{header: 'Example 1', id: 1, rows: [{index: 0}]}])
    setSelectedItems([])
  }
  const onAddExample = () => {
    const count = examples.length + 1
    examples.push({id: count, header: `Example ${count}`, rows: [{index: 0}]})
    form.onData('examples')(examples)
  }

  useEffect(() => {
    if (!examples || examples.length == 0) {
      form.onData('examples')([{header: 'Example 1', id: 1, rows: [{index: 0}]}])
    } else {
      form.onData('examples')(examples.map((example) => {
        return example.rows && example.rows.length ? example : Object.assign(example, {rows: [{index: 0}]})
      }))
    }
  }, [])

  const { isDragging } = useDragLayer(
    monitor => {
      return {
        isDragging: monitor.isDragging()
      }
    }
  )

  const promotedBulkActions = [
    {
      content: 'Delete',
      onAction: onDeleteExamples,
    },
  ];

  const renderItem = (example) => {
    const index = examples.indexOf(example)
    return (
      <ResourceItem
        id={example.id}
        accessibilityLabel={`View details for ${example.header}`}
      >
        <EditExample key={example.id} index={index} />
      </ResourceItem>
    );
  }

  return (<div className={isDragging ? 'dragging' : ''}><Stack vertical>
    <h3 className="spacer">Examples</h3>
    <ResourceList
     items={examples}
     renderItem={renderItem}
     selectedItems={selectedItems}
     onSelectionChange={setSelectedItems}
     promotedBulkActions={promotedBulkActions}
     resourceName={ {singular: 'example', plural: 'examples'} }
     alternateTool={<Button onClick={onAddExample}>Add Example</Button>}
    />
  </Stack></div>);
}
