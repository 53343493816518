import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import {FormContext} from '@thinkwell/react.common';
import {Stack, Autocomplete, Icon} from '@shopify/polaris';
import {SearchMinor, DeleteMajor} from '@shopify/polaris-icons';
import axios from 'axios'

export default function EditTemplate(props) {
  const form = useContext(FormContext)

  const name = props.name

  const template = form.field(name)

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateText = async(value) => {
    setInputValue(value);

    if (value.length < 2) {
      return
    }

    setLoading(true);
    const response = await axios({method: 'get', url: `/templates.json?q=${encodeURIComponent(value)}`})
    // Format data into JSON
    const data = response.data;
    const resultOptions = data.items.map((template) => {
      return {
        value: template,
        label: template.title
      }
    })
    setOptions(resultOptions);
    setLoading(false);
  }

  const onDeleteTemplate = () => {
    form.onData(name)(null)
    form.onData(`${name}_id`)(null)
  }

  const updateSelection = (selectedItems) => {
    const selected = selectedItems.map((selectedItem) => {
      return options.find((option) => option.value.id == selectedItem.id)
    }).pop()
    form.onData(name)(selected && selected.value)
    form.onData(`${name}_id`)(selected && selected.value.id)
    setInputValue('');
    setSelectedOptions([selected]);
  }

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search Template"
    />
  );

  return (
    <Stack vertical>
      <h3 className="spacer">{props.label}</h3>
      { template ? (<Stack>
          <Stack.Item><span className="edit-template">{template.title}</span></Stack.Item>
          <Stack.Item><span className="delete-template" onClick={onDeleteTemplate}><Icon source={DeleteMajor} /></span></Stack.Item>
        </Stack>) :
        <Autocomplete
          allowMultiple={false}
          options={options}
          selected={selectedOptions}
          onSelect={updateSelection}
          loading={loading}
          textField={textField}
        />
      }
    </Stack>
  );
}
