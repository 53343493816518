import React, { useState, useEffect, useContext } from 'react';
import {Stack} from '@shopify/polaris';
import {FormProvider} from '@thinkwell/react.common';
import {EditForm, TextField, Select} from '@thinkwell/react.polaris';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import {TemplatesContext} from '../contexts/Templates'
import EditMetatags from './EditMetatags'
import EditTags from '../EditTags'

export default function EditTemplate(props) {
  const [templates, fetchTemplates, fetchTemplatesState, upsertTemplate] = useContext(TemplatesContext)

  const item = props.item || {}

  const onEditSave = (response) => {
    props.onSave && props.onSave(response)
    upsertTemplate(response)
  }

  return (
    <FormProvider data={{data: item}}  omit={['tags']} rootName="template"><EditForm
      active={props.active}
      onActive={props.onActive}
      setActiveRef={props.setActiveRef}
      onSave={onEditSave}
      url={`/templates${item.id ? `/${item.id}` : ''}.json`}
      method={item.id ? 'put' : 'post'}
      title={item.id ? "Edit Template" : "Add Template"}>

      <TextField required
      label="Title"
      name="title"
      />

      <CKTextArea
      label="Header HTML"
      name="header_html"
      />

      <CKTextArea
      label="Footer HTML"
      name="footer_html"
      />

      <TextField
      multiline
      label="Styles"
      name="styles"
      />

      <EditMetatags />

      <EditTags />

    </EditForm></FormProvider>
  );
}
