import React, { useState, useEffect, useContext } from 'react';
import {TextStyle, Layout, Card, Stack, Link, Button, InlineError} from '@shopify/polaris';
import {useReducerRequest} from '@thinkwell/react.common';
import ReactHtmlParser from 'react-html-parser'
import EditTag from './EditTag';
import { TagContext } from '../contexts/Tag'
import compact from 'lodash/compact'

const formatDate = (dateStr) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(dateStr))
};

export default function Tag(props) {
  const [tag, setTag] = useContext(TagContext);

  const [stateDelete, onDelete] = useReducerRequest('delete', {onSuccess: () => {
    props.onDelete && props.onDelete()
  }})

  const deleteTag = () => {
    onDelete(`/tags/${tag.id}`,
      null,
      'Are you sure you want to delete this tag? This action is irreversible.'
    )
  }

  return (
    <div className="tag">
      <Layout>
        <Layout.Section primary>
          <Card>
            <Card.Header title={tag.value}>
              <Button plain onClick={() => props.navigateToRef.current('tags', {withContext: 'url'}) }>Back to Tags</Button>
              <EditTag item={tag} onSave={setTag}/>
              <Button plain destructive loading={stateDelete.requesting} onClick={deleteTag}>Delete</Button>
              {stateDelete.error ? <InlineError message={stateDelete.error} fieldID="deleteUserErrorFieldID" /> : null}
            </Card.Header>
            <Card.Section>
              <div>Updated at : {formatDate(tag.updated_at)}</div>
            </Card.Section>
            {
              tag.questions && tag.questions.length > 0 ?
                <Card.Section title="Questions">
                {
                  tag.questions.map((question) => {
                    return (<div key={question.id}><a href={`/questions/${question.id}`}>{question.question_name}</a></div>)
                  })
                }
                </Card.Section>
              : null
            }
            {
              tag.worksheets && tag.worksheets.length > 0 ?
                <Card.Section title="Worksheets">
                {
                  tag.worksheets.map((worksheet) => {
                    return (<div key={worksheet.id}><a href={`/worksheets/${worksheet.id}`}>{worksheet.title}</a></div>)
                  })
                }
                </Card.Section>
              : null
            }
            {
              tag.templates && tag.templates.length > 0 ?
                <Card.Section title="Templates">
                {
                  tag.templates.map((template) => {
                    return (<div key={template.id}><a href={`/templates/${template.id}`}>{template.title}</a></div>)
                  })
                }
                </Card.Section>
              : null
            }
            {
              tag.thinkwell_ids && tag.thinkwell_ids.length > 0 ?
                <Card.Section title="Thinkwell IDs">
                {
                  tag.thinkwell_ids.map((thinkwell_id) => {
                    return (<div key={thinkwell_id}><a href={`/questions?thinkwell_id=${thinkwell_id}`}>{thinkwell_id}</a></div>)
                  })
                }
                </Card.Section>
              : null
            }
          </Card>
        </Layout.Section>
      </Layout>
    </div>
  );
}
