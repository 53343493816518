import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser'

export default function Footer(props) {
  return (
    <div className="footer">
      {ReactHtmlParser(props.html)}
    </div>
  );
}
