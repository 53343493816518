import React, { useState } from 'react';

const QuestionContext = React.createContext();

const QuestionProvider = (props) => {
  const [question, setQuestion] = useState(props.value)

  return (
    <QuestionContext.Provider value={[question, setQuestion]}>
      {props.children}
    </QuestionContext.Provider>
  );
};

export { QuestionContext, QuestionProvider}
