import React, { useState, useEffect, useContext, useRef } from 'react';
import {ResourceList as ResourceListShopify, Avatar, TextStyle, Stack, Link, TopBar, Icon} from '@shopify/polaris';
import {EditMinor} from '@shopify/polaris-icons';
import {SearchContext} from '@thinkwell/react.common';
import {ResourceList} from '@thinkwell/react.polaris';
import EditQuestion from './EditQuestion';
import MainFrame from '../MainFrame';
import { QuestionsContext } from '../contexts/Questions'
import ReactHtmlParser from 'react-html-parser'
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';

const formatDate = (dateStr) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(dateStr))
};

export default function Questions(props) {
  const [questions, fetchQuestions, fetchQuestionsState, upsertQuestion] = useContext(QuestionsContext);
  const [search, setSearch] = useContext(SearchContext);

  const navigateToRef = useRef()
  const [addQuestionActive, setAddQuestionActive] = useState(false);
  const [editQuestionItem, setEditQuestionItem] = useState()
  const [isQuestionMenuOpen, setIsQuestionMenuOpen] = useState(false);
  const toggleIsQuestionMenuOpen = () => setIsQuestionMenuOpen((isQuestionMenuOpen) => !isQuestionMenuOpen);

  const onSearch = async (params) => {
    const questions = await fetchQuestions(`/questions.json`, params)
    if (questions.length == 1) {
      window.location.assign(`/questions/${questions[0].id}`);
    }
  }

  const delayedSearch = useRef(debounce(onSearch, 1000));

  const onSearchChange = (value) => {
    setSearch(value)
  }

  const handleKeyup = (e) => {
    const {keyCode} = e;
    if (keyCode === 13 && e.target.placeholder == 'Search questions') { // enter
      onSearch({query: e.target.value})
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);
  }, [])

  const questionMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Question', onAction: () => { setEditQuestionItem(null); setAddQuestionActive(true) }},
             {content: 'Logout', url: '/logout' }
          ]
         }
       ]}
       name="Questions"
       initials="QU"
       open={isQuestionMenuOpen}
       onToggle={toggleIsQuestionMenuOpen}
     />
   );

  const renderQuestion = (item) => {
    const {question_name} = item.serialized_data;
    return (
      <ResourceListShopify.Item
        id={item.id}
        accessibilityLabel={`View details for ${question_name}`}
        onClick={() => { navigateToRef.current(`questions/${item.id}`, {withContext: true}) } }
        shortcutActions={[{content: <Icon source={EditMinor} />, onAction: () => { setEditQuestionItem(item); setAddQuestionActive(true) }}]}
      >
        <Stack>
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{ReactHtmlParser(question_name)}</TextStyle>
            </h3>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="subdued">{formatDate(item.updated_at)}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceListShopify.Item>
    );
  };

  const onAddQuestion = (question) => {
    if (!editQuestionItem) {
      navigateToRef.current(`questions/${question.id}`)
    }
  }

  return (
    <div>
    <MainFrame onSearchChange={onSearchChange} searchPlaceHolder="Search questions"
    userMenu={questionMenuMarkup} navigateToRef={navigateToRef}>
      { addQuestionActive ?
        <EditQuestion
          item={editQuestionItem}
          active={addQuestionActive}
          onActive={(active) => { if (active != addQuestionActive) { setAddQuestionActive(active) }}}
          onSave={onAddQuestion}
        /> :
        <ResourceList withoutSort items={questions} fetchItems={fetchQuestions} fetchItemsState={fetchQuestionsState}
        url="/questions.json" renderItem={renderQuestion}
        resourceName={ {singular: 'question', plural: 'questions'} }></ResourceList>
      }
    </MainFrame>
  </div>);
}
