import React, {useState, useRef} from 'react';
import {TopBar} from '@shopify/polaris';
import {SearchProvider} from '@thinkwell/react.common';
import {AppProvider} from '@thinkwell/react.polaris';
import { QuestionProvider } from '../contexts/Question'
import Question from './Question'
import EditQuestion from './EditQuestion'
import MainFrame from '../MainFrame';

export default function Show(props) {
  const navigateToRef = useRef()
  const [search, setSearch] = useState()
  const [addQuestionActive, setAddQuestionActive] = useState(false);
  const [isQuestionMenuOpen, setIsQuestionMenuOpen] = useState(false);
  const toggleIsQuestionMenuOpen = () => setIsQuestionMenuOpen((isQuestionMenuOpen) => !isQuestionMenuOpen);

  const questionMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Question', onAction: () => { setAddQuestionActive(true) }},
             {content: 'Logout', url: '/logout' }
           ]
         }
       ]}
       name="Questions"
       initials="QU"
       open={isQuestionMenuOpen}
       onToggle={toggleIsQuestionMenuOpen}
     />
   );

  const onSearchKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      navigateToRef.current(`questions?q=${encodeURIComponent(search)}`)
    }
  }

  const onSearchChange = (value) => {
    setSearch(value)
  }

  const onAddQuestion = (question) => {
    navigateToRef.current(`questions/${question.id}`)
  }

  return (
  <AppProvider>
    <SearchProvider>
      <MainFrame searchPlaceHolder={props.item.thinkwell_id} onSearchChange={onSearchChange}
      onSearchKeyPress={onSearchKeyPress} navigateToRef={navigateToRef} userMenu={questionMenuMarkup}>
        <QuestionProvider value={props.item}>
          { addQuestionActive ?
            <EditQuestion
              active={addQuestionActive}
              onActive={(active) => { if (active != addQuestionActive) { setAddQuestionActive(active) }}}
              onSave={onAddQuestion}
            /> : null
          }
          <div style={{display: addQuestionActive ? 'none' : 'block'}}>
            <Question
              navigateToRef={navigateToRef}
              onClone={onAddQuestion}
            />
          </div>
        </QuestionProvider>
      </MainFrame>
    </SearchProvider>
  </AppProvider>)
}
