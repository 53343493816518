import React, { useState, useEffect, useContext } from 'react';
import {Stack} from '@shopify/polaris';
import {FormProvider} from '@thinkwell/react.common';
import {CKTextArea} from '@thinkwell/react.ckeditor';
import {EditForm, TextField, Select} from '@thinkwell/react.polaris';
import {WorksheetsContext} from '../contexts/Worksheets'
import EditExamples from './EditExamples'
import EditTags from '../EditTags'
import EditTemplate from './EditTemplate'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export default function EditWorksheet(props) {
  const [worksheets, fetchWorksheets, fetchWorksheetsState, upsertWorksheet] = useContext(WorksheetsContext)

  const item = props.item || {}

  const onEditSave = (response) => {
    props.onSave && props.onSave(response)
    upsertWorksheet(response)
  }

  return (
    <DndProvider backend={HTML5Backend}>
    <FormProvider data={{data: item}} omit={['tags']} rootName="worksheet"><EditForm
      active={props.active}
      onActive={props.onActive}
      setActiveRef={props.setActiveRef}
      onSave={onEditSave}
      url={`/worksheets${item.id ? `/${item.id}` : ''}.json`}
      method={item.id ? 'put' : 'post'}
      title={item.id ? item.title : "Add Worksheet"}>
      <Stack vertical>
        <TextField required
          label="Title"
          name="title"
        />

        <TextField required
          label="Main Header"
          name="main_header"
        />

        <CKTextArea required
          label="Sub Header"
          name="sub_header"
        />

        <EditExamples />

        <EditTemplate
          label="Template"
          name="template"
        />

        <EditTags />
      </Stack>
    </EditForm></FormProvider></DndProvider>
  );
}
