import React from 'react';
import {PagingProvider, SearchProvider} from '@thinkwell/react.common';
import {AppProvider} from '@thinkwell/react.polaris';
import { QuestionsProvider } from '../contexts/Questions'
import Questions from './Questions'

export default function Index(props) {
  return ( <AppProvider>
    <SearchProvider search={props.query}>
    <PagingProvider previous_page_info={props.previous_page_info} next_page_info={props.next_page_info} page={props.page}>
    <QuestionsProvider value={props.items}>
      <Questions />
    </QuestionsProvider>
    </PagingProvider>
    </SearchProvider></AppProvider>)
}
