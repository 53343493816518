import React, { useState, useEffect, useContext, useRef } from 'react';
import {ResourceList as ResourceListShopify, Avatar, TextStyle, Stack, Link, TopBar, Icon} from '@shopify/polaris';
import {EditMinor} from '@shopify/polaris-icons';
import ReactHtmlParser from 'react-html-parser'
import {SearchContext} from '@thinkwell/react.common';
import {ResourceList} from '@thinkwell/react.polaris';
import EditTemplate from './EditTemplate';
import MainFrame from '../MainFrame';
import { TemplatesContext } from '../contexts/Templates'
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';

const formatDate = (dateStr) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(dateStr))
};

export default function Templates(props) {
  const [templates, fetchTemplates, fetchTemplatesState] = useContext(TemplatesContext);
  const [search, setSearch] = useContext(SearchContext);

  const navigateToRef = useRef()
  const [addTemplateActive, setAddTemplateActive] = useState(false);
  const [editTemplateItem, setEditTemplateItem] = useState()
  const [isTemplateMenuOpen, setIsTemplateMenuOpen] = useState(false);
  const toggleIsTemplateMenuOpen = () => setIsTemplateMenuOpen((isTemplateMenuOpen) => !isTemplateMenuOpen);

  const templatesSorted = templates.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

  const onSearch = (params) => {
    fetchTemplates(`/templates.json`, params)
  }

  const delayedSearch = useRef(debounce(onSearch, 1000));

  const onSearchChange = (value) => {
    setSearch(value)
    delayedSearch.current({query: value})
  }

  const templateMenuMarkup = (
     <TopBar.UserMenu
       actions={[
         {
           items: [
             {content: 'Add Template', onAction: () => { setEditTemplateItem(null); setAddTemplateActive(true) }},
             {content: 'Logout', url: '/logout'}
           ]
         }
       ]}
       name="Templates"
       initials="T"
       open={isTemplateMenuOpen}
       onToggle={toggleIsTemplateMenuOpen}
     />
   );

  const renderTemplate = (item) => {
    const {title} = item;
    return (
      <ResourceListShopify.Item
        id={item.id}
        onClick={() => navigateToRef.current(`templates/${item.id}`, {withContext: true})}
        shortcutActions={[{content: <Icon source={EditMinor} />, onAction: () => { setEditTemplateItem(item); setAddTemplateActive(true) }}]}
      >
        <Stack>
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{title}</TextStyle>
            </h3>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="subdued">{formatDate(item.updated_at)}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceListShopify.Item>
    );
  };

  return (
    <div>
    <MainFrame onSearchChange={onSearchChange} searchPlaceHolder="Search templates"
    userMenu={templateMenuMarkup} navigateToRef={navigateToRef}>
      {
        addTemplateActive ?
        <EditTemplate
          item={editTemplateItem}
          active={addTemplateActive}
          onActive={(active) => { if (active != addTemplateActive) { setAddTemplateActive(active) }}}
        /> :
        <ResourceList withoutSort items={templatesSorted} fetchItems={fetchTemplates} fetchItemsState={fetchTemplatesState}
        url="/templates.json" renderItem={renderTemplate}
        resourceName={ {singular: 'template', plural: 'templates'} }></ResourceList>
      }
    </MainFrame>
  </div>);
}
